






























































































































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

// Components
import StepperNavigation from '@/components/zones/stepperNavigation.vue';
import MenuOverflowDisplay, {
	IGenericMenu,
} from '@/components/generic/menuOverflowDisplay/menuOverflowDisplay.vue';
import LoadingData from '@/components/datadisplay/LoadingData.vue';

import NoData from '@/components/datadisplay/NoData.vue';

// State module imports
import { ZonesModule } from '@/store/zones/zones';
import { SuppliersModule } from '@/store/suppliers/suppliers';
import { AuthStore } from '@/store/auth/auth';
import { HeaderModule } from '@/store/header/header';

// Classes

// Service Imports
import { SupplierZoneServicesService } from '@/services/supplierZones/supplierZoneServicesService';
import { SupplierZoneService } from '@/services/supplierZones/supplierZoneService';
import { SupplierZoneReviewService } from '@/services/supplierZones/supplierZoneReviewService';
import { ISupplierZonePrice } from '@/classes/supplierZones/ISupplierZonePrice';

@Component({
	components: { StepperNavigation, MenuOverflowDisplay, NoData, LoadingData },
})
export default class CompleteStep extends Vue {
	//Services
	supplierZoneServicesService = new SupplierZoneServicesService();
	supplierZoneService = new SupplierZoneService();
	supplierZoneReviewService = new SupplierZoneReviewService();

	// Modules
	zonesModule = ZonesModule;
	authModule = AuthStore;

	// Props
	surchargePriceMenu = false;
	pricingItem = { ShowModal: false };
	showRejectPopup = false;
	rejectionNotes = '';
	isFormValid = false;
	isLoading = true;

	surchargesProps: IGenericMenu = {
		leftHeader: 'Postcode',
		rightHeader: 'Price (£)',
		leftPropName: 'Postcode',
		rightPropName: 'Surcharge (£)',
	};

	menuPricingProps: IGenericMenu = {
		leftHeader: 'Type',
		rightHeader: 'Price',
		leftPropName: 'Name',
		rightPropName: 'Value',
	};

	buttonEvent: any;
	ShowSurchargeMenu(item: any, event: any) {
		item.ShowSurchargeModal = true;
		this.pricingItem = item;
		this.buttonEvent = event;
	}

	ShowPricingMenu(item: any, event: any) {
		item.ShowPricingModal = true;
		this.pricingItem = item;
		this.buttonEvent = event;
	}
	ConvertPriceToArray(price: ISupplierZonePrice): Array<any> {
		const arr = [];
		if (price.Lift) {
			arr.push({
				Name: 'Lift',
				Value: price.Lift,
			});
		}
		if (price.Haulage) {
			arr.push({
				Name: 'Haulage',
				Value: price.Haulage,
			});
		}

		if (price.Tonnage) {
			arr.push({
				Name: 'Tonnage',
				Value: price.Tonnage,
			});
		}

		if (price.OnDelivery) {
			arr.push({
				Name: 'On Delivery',
				Value: price.OnDelivery,
			});
		}

		if (price.OnDemand) {
			arr.push({
				Name: 'On Demand',
				Value: price.OnDemand,
			});
		}

		if (price.OverweightPricePerKg) {
			arr.push({
				Name: 'Overweight (Kg)',
				Value: price.OverweightPricePerKg,
			});
		}

		if (price.ExcessTonnage) {
			arr.push({
				Name: 'Excess (Ton)',
				Value: price.ExcessTonnage,
			});
		}

		if (price.MinTonnes) {
			arr.push({
				Name: 'Min Tonnes',
				Value: price.MinTonnes,
			});
		}

		if (price.MaxTonnes) {
			arr.push({
				Name: 'Max Tonnes',
				Value: price.MaxTonnes,
			});
		}

		return arr;
	}
	onScroll() {
		if (this.buttonEvent) {
			this.buttonEvent.srcElement.click();
			this.buttonEvent = null;
		}
	}

	Save() {
		if (this.authModule.IsReviewer) {
			this.supplierZoneReviewService
				.ConfirmReview(Number(this.$route.params.zoneId))
				.then(data => {
					this.$router.replace('/zones');

					Vue.toasted.show('Review Confirmation Successful', {
						theme: 'toasted-primary',
						position: 'bottom-center',
						singleton: true,
						duration: 2000,
					});
				});
		} else {
			this.supplierZoneReviewService
				.RequestReview(Number(this.$route.params.zoneId))
				.then(data => {
					this.$router.replace('/zones');

					Vue.toasted.show('Review Request Successful', {
						theme: 'toasted-primary',
						position: 'bottom-center',
						singleton: true,
						duration: 2000,
					});
				});
		}
	}

	Reject() {
		this.showRejectPopup = false;

		this.supplierZoneReviewService
			.ReviewRejected(Number(this.$route.params.zoneId), this.rejectionNotes)
			.then(data => {
				HeaderModule.UpdateStepperIndex(0);

				this.$router.replace('/zones');

				Vue.toasted.show('Zone Rejected', {
					theme: 'toasted-primary',
					position: 'bottom-center',
					singleton: true,
					duration: 2000,
				});
			});
	}

	GetHierarchyName(level: number) {
		switch (level) {
			case 0:
				return 'Locations';
			case 1:
				return 'Countries';
			case 2:
				return 'Regions';
			case 3:
				return 'Counties';
			case 4:
				return 'Postcode District';
			case 5:
				return 'Postcode Sector';
		}
	}

	created() {
		if (this.zonesModule.GetGroupedZonePrices.length === 0) {
			this.supplierZoneServicesService
				.GetSupplierZonePricesGrouped(Number(this.$route.params.zoneId))
				.then(data => {
					this.zonesModule.UpdateGroupedZonePrices(data);
					this.isLoading = false;
				});
		}

		if (
			this.zonesModule.GetGroupedZonePrices.length === 0 &&
			SuppliersModule.GetSelectedSupplier.SupplierId
		) {
			this.supplierZoneService
				.GetSupplierZoneGrouped(
					SuppliersModule.GetSelectedSupplier.SupplierId,
					parseInt(this.$route.params.zoneId)
				)
				.then(zoneGroup => {
					this.zonesModule?.UpdateSelectedGroupZone(zoneGroup);
					this.isLoading = false;
				});
		} else {
			this.isLoading = false;
		}
	}
}

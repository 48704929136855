import { axiosBase } from '../base/baseAxios';
import { AxiosResponse } from 'axios';
import { SuppliersModule } from '@/store/suppliers/suppliers';

export class SupplierZoneReviewService {
	private suppliersModule = SuppliersModule;

	public RequestReview(zoneId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			axiosBase
				.get(`/SupplierZones/Review/RequestReview?zoneId=${zoneId}`)
				.then(
					(response: AxiosResponse<number>) => {
						resolve(response.data);
					},
					(err: any) => {
						reject(err);
					}
				);
		});
	}

	public ConfirmReview(zoneId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			axiosBase
				.get(`/SupplierZones/Review/ConfirmReview?zoneId=${zoneId}`)
				.then(
					(response: AxiosResponse<number>) => {
						resolve(response.data);
					},
					(err: any) => {
						reject(err);
					}
				);
		});
	}

	public ReviewRejected(
		zoneId: number,
		rejectionReason: string
	): Promise<number> {
		return new Promise((resolve, reject) => {
			axiosBase
				.get(
					`/SupplierZones/Review/ReviewRejected?zoneId=${zoneId}&rejectionReason=${rejectionReason}`
				)
				.then(
					(response: AxiosResponse<number>) => {
						resolve(response.data);
					},
					(err: any) => {
						reject(err);
					}
				);
		});
	}
}

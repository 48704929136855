var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticStyle:{"height":"100%","padding":"0"},attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"gutters":""}},[_c('v-col',{staticClass:"border-column",staticStyle:{"height":"100%"},attrs:{"cols":"12","lg":"3"}},[_c('v-row',[_c('v-col',[_c('h2',[_vm._v("Confirm Coverage")])]),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"align-self":"center"}},[(!_vm.authModule.IsReviewer)?_c('v-btn',{staticClass:"px-7",attrs:{"small":"","outlined":"","rounded":"","right":""},on:{"click":function($event){return _vm.$router.replace(("/zones/" + (_vm.$route.params.zoneId) + "/region"))}}},[_vm._v("Edit")]):_vm._e()],1)],1),_c('v-row',{staticStyle:{"overflow-y":"scroll","height":"90%"}},[_c('v-col',{staticStyle:{"height":"90%","padding-top":"0"}},[(_vm.zonesModule.GetSelectedZoneGroup.length < 1 && !_vm.isLoading)?_c('no-data',{attrs:{"noDataText":'No Services Created',"noDataImage":'mdi-dump-truck'}}):_vm._e(),(_vm.zonesModule.GetSelectedZoneGroup.length > 1)?_c('v-list',_vm._l((_vm.zonesModule.GetSelectedZoneGroup),function(postalComponents){return _c('v-list-group',{key:postalComponents.Key.Level,attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-row',[_c('v-col',{attrs:{"align-self":"center"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
														_vm.GetHierarchyName(postalComponents.Key.Level)
													)}})],1),_c('v-col',{attrs:{"align-self":"center"}},[_c('v-list-item-title',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(postalComponents.Components.length)+" ")])],1)],1)],1)]},proxy:true}],null,true)},[_c('v-virtual-scroll',{attrs:{"height":postalComponents.Components.length > 5
											? 300
											: postalComponents.Components.length * 50,"item-height":50,"items":postalComponents.Components},scopedSlots:_vm._u([{key:"default",fn:function(ref){
											var item = ref.item;
return [_c('v-list-item',{key:item.PostalComponent.Id},[_c('v-list-item-content',{staticStyle:{"margin-left":"20px","padding":"0"}},[_c('v-list-item-title',[_c('v-avatar',{attrs:{"size":"35"}},[_c('span',[_vm._v(_vm._s(item.PostalComponent.Name.substring(0, 2)))])]),_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(item.PostalComponent.Name))])],1)],1)],1),_c('v-divider')]}}],null,true)})],1)}),1):_vm._e()],1)],1)],1),_c('v-col',{staticStyle:{"height":"100%"},attrs:{"cols":"12","lg":"9","id":"stream-container"}},[_c('v-row',[_c('v-col',[_c('h2',[_vm._v("Confirm Service Details")])]),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"align-self":"center"}},[(!_vm.authModule.IsReviewer)?_c('v-btn',{staticClass:"px-7",attrs:{"small":"","outlined":"","rounded":"","right":""},on:{"click":function($event){return _vm.$router.replace(("/zones/" + (_vm.$route.params.zoneId) + "/service"))}}},[_vm._v("Edit")]):_vm._e()],1)],1),_c('v-row',{staticStyle:{"height":"90%","overflow-y":"scroll"}},[_c('v-col',{staticStyle:{"height":"90%","padding-top":"0"}},[(_vm.isLoading)?_c('loading-data',{attrs:{"loadingDataText":'Loading...'}}):_vm._e(),(_vm.zonesModule.GetGroupedZonePrices.length < 1 && !_vm.isLoading)?_c('no-data',{attrs:{"noDataText":'No Services Created',"noDataImage":'mdi-dump-truck'}}):_vm._e(),_c('v-list',_vm._l((_vm.zonesModule.GetGroupedZonePrices),function(group){return _c('v-list-group',{key:group.Name,attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-row',[_c('v-col',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(group.Name)}})],1),_c('v-col',[_c('v-list-item-title',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(group.ServicePrices.length)+" ")])],1)],1)],1)]},proxy:true}],null,true)},[_c('v-virtual-scroll',{attrs:{"id":"price-virtual-scroll","height":group.ServicePrices.length > 5
											? 300
											: group.ServicePrices.length * 130,"item-height":145,"items":group.ServicePrices},scopedSlots:_vm._u([{key:"default",fn:function(ref){
											var item = ref.item;
return [_c('v-list-item',{key:item.Id},[_c('v-card',{directives:[{name:"scroll",rawName:"v-scroll:#price-virtual-scroll",value:(_vm.onScroll),expression:"onScroll",arg:"#price-virtual-scroll"},{name:"scroll",rawName:"v-scroll:#stream-container",value:(_vm.onScroll),expression:"onScroll",arg:"#stream-container"}],staticStyle:{"margin":"12px","width":"100%"}},[_c('v-list-item-content',{staticClass:"ml-2"},[_c('v-list-item-title',{staticClass:"text--darken-1 primary--text mb-6"},[_vm._v(" "+_vm._s(item.Service.ServiceDescription)+" ")]),_c('v-row',{staticClass:"text-subtitle-2 text--secondary text-field"},[_c('v-col',[_vm._v("Service Status")]),_c('v-col',[_vm._v("Surcharged (£)")]),_c('v-col',[_vm._v("Service Days")]),_c('v-col',[_vm._v("Frequency")]),_c('v-col',[_vm._v("Pricing (£)")])],1),_c('v-row',{staticClass:"text-subtitle-2 text-field"},[_c('v-col',[_vm._v(" "+_vm._s(!item.Service.Status ? 'Live' : 'Not Live')+" ")]),_c('v-col',[_c('MenuOverflowDisplay',{attrs:{"props":_vm.surchargesProps,"items":item.Exceptions.filter(
																		function (xx) { return xx.Surcharge > 0 && item.Id !== xx.Id; }
																	)}})],1),_c('v-col',_vm._l((item.DaysDropDown),function(day){return _c('span',{key:day.Value},[_vm._v(_vm._s(day.Name.substring(0, 3) + ', '))])}),0),_c('v-col',_vm._l((item.FrequenciesDropDown),function(day){return _c('span',{key:day.Value},[_vm._v(_vm._s(day.Name + ', '))])}),0),_c('v-col',[_c('MenuOverflowDisplay',{attrs:{"props":_vm.menuPricingProps,"items":_vm.ConvertPriceToArray(item)}})],1)],1)],1)],1)],1)]}}],null,true)},[_vm._v(" > ")])],1)}),1)],1)],1)],1)],1)],1),_c('StepperNavigation',{on:{"next-step":_vm.Save,"rejected":function($event){_vm.showRejectPopup = true}}}),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showRejectPopup),callback:function ($$v) {_vm.showRejectPopup=$$v},expression:"showRejectPopup"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary white--text"},[_vm._v(" Rejection ")]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-textarea',{attrs:{"label":"Rejection Reason","floating":"","rules":[
							function (v) { return !!v || 'A reason must be supplied'; },
							function (v) { return (v && v.length > 10) ||
								'Description must be greater than 10 characters'; } ]},model:{value:(_vm.rejectionNotes),callback:function ($$v) {_vm.rejectionNotes=$$v},expression:"rejectionNotes"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.isFormValid,"bottom":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.Reject()}}},[_vm._v(" Confirm ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }